/* Card Page */

.card_page {
    margin-top: 27px;
}

.card_icon + .card_icon {
    margin-left: 30px;
}

.breadcrumbs {
    max-width: 100%;
    height: 25px;
    overflow: auto;
    margin-top: 15px;
    margin-bottom: 10px;
    color: rgba(181, 183, 189, 1);
    white-space: nowrap;
}

.breadcrumbs::-webkit-scrollbar {
    display: none;
}

.card_images {
    position: relative;
}

.similar_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    width: 105px;
    height: 38px;
    position: absolute;
    bottom: 50px;
    right: 20px;
    opacity: 7;
    border-radius: 10px;
}

.similar_btn button {
    color: rgba(131, 134, 144, 1);
    font-size: 14px;

}

.similar_btn-arrow {
    margin-left: 8px;
}

.card_images_img {
    width: 325px;
    height: 427px;
    border-radius: 20px;
    object-fit: cover;
}

.card_page-img {
    width: 100%;
}

.card_title {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.card_price {
    font-size: 32px;
    font-weight: bold;
    font-family: 'OpenSansBold';
}

.card_seller-title {
    margin-top: 20px;
    font-size: 18px;
}

.card_reviews {
    margin-top: 10px;
}

.card_reviews-stars {
    margin-right: 20px;
}

.cardPage_average {
    font-size: 18px;
    font-family: OpenSansBold;
    margin-right: 10px;
}

.card_page-count {
    font-size: 16px;
    font-family: OpenSansSemiBold;
}

.card_seller_info {
    margin-bottom: 10px;
}

.card_seller-address {
    width: 310px;
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
}

.card_btns {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.main_black_btn {
    background-color: rgba(22, 22, 22, 1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
    color: white;
    font-family: 'OpenSansSemiBold';
}

.main_black_btn:hover {
    background-color: rgba(131, 134, 144, 1);
}

.black_btn {
    background-color: rgba(22, 22, 22, 1);
    width: 156px;
    height: 54px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
}

.white_btn {
    width: 156px;
    height: 54px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_description-title {
    margin-bottom: 15px;
}

.card_description-text {
    color: rgba(131, 134, 144, 1);
    white-space: pre-line;
}

.card_backbtn {
    margin-top: 30px;
    margin-bottom: 30px;
}

.card_statistic-text {
    color: rgba(181, 183, 189, 1);
}

.card_statistic-text + .card_statistic-text {
    margin-top: 5px;
    margin-bottom: 120px;
}

/* Board Page */

.board_page {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
}

.board_page:last-child {
    margin-bottom: 100px;
}

.plus_card_block {
    padding: 15px;
    background-color: rgba(22, 22, 22, 1);
    border-radius: 20px;
}

/* Profile page */
.profile {
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 20px;
    padding-bottom: 80px;
}

.profile_info-icon {
    position: relative;
}

.profile_dots {
    position: absolute;
    top: 15px;
    right: 150px;
}

.profile_filter {
    position: absolute;
    top: 25px;
    right: 180px;
}

.profile_info-avatar {
    margin-right: 15px;
}

.profile_info-img {
    width: 69px;
    height: 69px;
    border-radius: 50%;
    object-fit: cover;
}

.profile_info-name {
    width: 150px;
    font-size: 18px;
}

.profile_info-company_name {
    width: 136px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.profile_info-phone {
    color: rgba(131, 134, 144, 1);
    font-size: 14px;
}

.nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.nav label {
    width: 50%;
    transition: all 0.3s ease;
}

.nav::after {
    content: '';
    display: block;
    background-color: rgba(181, 183, 189, 1);
    width: 100%;
    height: 1px;
    position: absolute;
    top: 35px;
}


#active:checked ~ .profile_card-active,
#archive:checked ~ .profile_card-archive {
    color: rgba(7, 12, 32, 1);
}

.profile_cards input {
    display: none;
}

.slider {
    background-color: rgba(7, 12, 32, 1);
    position: absolute;
    top: 90px;
    width: 140px;
    height: 5px;
    transition: all 0.3s ease;
}

.p-l-50 {
    left: 50%;
}

/* #archive:checked ~ .slider {
    left: 50%;
} */

.profile_cards {
    margin-top: 29px;
}

.profile_cards-title {
    font-size: 20px;
    margin-bottom: 25px;
}

.profile_search {
    width: 276px;
    height: 47px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 30px;
    padding: 13px 30px;
}

.profile_reviews {
    margin-top: 20px;
    margin-bottom: 25px;
}


.profile_reviews span {
    font-size: 18px;
    margin-right: 10px;
    font-family: OpenSansBold;
}

/* Messages */

.messagePage {
    padding: 45px 25px;
}

.messages_header-title {
    font-size: 20px;
    margin-right: 23px;
}

.messages_header-count {
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    color: white;
    background-color: rgba(252, 55, 42, 1);
    border-radius: 50%;
}

.messages_header-icon + .messages_header-icon {
    margin-left: 30px;
}

.messages_list {
    margin-top: 45px;
}

/* Dialog Page */

.chatPage {
    position: relative;
    padding: 33px 25px;
}

.chat_info-title {
    font-size: 16px;
}

.chat_info-subtitle {
    font-size: 14px;
}

.chat_info {
    margin-left: 31px;
    width: 80%;
}


.chat_list {
    margin-top: 45px;
    margin-bottom: 60px;
}

.chat_input {
    margin-top: 40px;
    /* width: 325px; */
    /* на других устройствах плохо выглядит */
    width: 90%;
    position: fixed;
    bottom: 25px;
    left: 20px;
    padding: 17px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    z-index: 3;
}

.chat_textarea {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    resize: none;
    outline: none;
}

.chat_icon {
    margin-right: 21px;
}


/* SubCategoryPage */

.categoryPage {
    position: relative;
    padding: 45px 15px 105px 15px;
    min-height: 100vh;
    overflow-x: auto;
}

.categoryPage-title {
    margin-left: 20px;
}

.subCategory_header {
    margin-top: 26px;
    margin-left: 25px;
}

.categoryPage-header {
    margin-bottom: 25px;
    margin-top: 25px;
}

.subCategory_header-title {
    margin-left: 30px;
    font-size: 20px;
}

.categoryPage_search {
    width: 276px;
    height: 47px;
    background-color: rgba(236, 236, 236, 1);
    padding: 13px 15px 13px 30px;
    border-radius: 10px;
}

.categoryPage_list {
    margin-top: 30px;
}

/* Service Page */

.servicePage {
    padding: 45px 15px 105px 15px;
    min-height: 100vh;
    overflow-x: auto;
}

.serviceItem + .serviceItem {
    margin-top: 30px;
}

.serviceItem_header {
    align-items: flex-start;
}

.serviceItem_header-img {
    width: 59px;
    height: 79px;
    border-radius: 7px;
    margin-right: 15px;
    object-fit: cover
}

.serviceItem_header_info-title {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.serviceItem_header_info-price {
    font-size: 16px;
}

.serviceItem_description {
    font-size: 10px;
    overflow: hidden;
    color: rgba(131, 134, 144, 1);
    margin-top: 15px;
    line-height: 15.57px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

.serviceItem_time {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 10px;
    color: rgba(181, 183, 189, 1);
}

/* EditProfilePage */

.editPAge {
    padding: 47px 25px;
}


.editProfile_avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.editProfile_avatar-img {
    width: 83px;
    height: 83px;
    border-radius: 50%;
    margin-top: 30px;
    margin-bottom: 15px;
}

.editProfile_avatar-span {
    color: #838690;
    font-weight: bold;
}

.editProfile_btns {
    margin-top: 10vh;
}

.editProfile_btn + .editProfile_btn {
    margin-top: 10px;
}

.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.auth_h1 {
    text-align: center;
    font-family: OpenSansBold;
    font-size: 32px;
    margin-top: 25%;
    margin-bottom: 20px;
}

.auth_info {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.auth_missPassword {
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
    margin-left: 49px;
}


.auth_btns {
    margin-top: 90px;
}

.auth_btn {
    margin-top: 10px;
}

.new_password-title {
    font-size: 32px;
    margin-top: 160px;
    margin-bottom: 10px;
}

.new_password-subtitle {
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 10px;
}

.new_password_btn {
    margin-top: 180px;
}

.reg_title, .reg_btns {
    margin-top: 70px;
}

.reg_btn + .reg_btn {
    margin-top: 10px;
}

/* Review Page */

.reviewPage {
    padding: 47px 25px;
}

.reviewPage-title {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 37px;
}

.average {
    font-size: 38px;
    margin-right: 20px;
    font-family: OpenSansBold;
}

.review_count {
    display: block;
    font-family: OpenSansSemiBold;
    font-size: 14px;
    color: rgba(181, 183, 189, 1);
    margin-top: 30px;
}

.review_list {
    margin-top: 30px;
}

.addReview {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 104px;
    background-color: white;
}

.addReviewPage {
    padding: 47px 20px;
}

.addReview-title {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.addReview-subtitle {
    display: block;
    font-size: 14px;
    color: rgba(131, 134, 144, 1);
    margin-bottom: 30px;
}

.addReview-text {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    color: rgba(181, 183, 189, 1);
}

.addReview-textarea {
    width: 325px;
    height: 312px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    border: none;
    margin-bottom: 46px;
    padding: 20px;
    color: #838690;
}

.favoritePage {
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 45px;
    padding-bottom: 125px;
}

.favoritePage_subMenu {
    position: absolute;
    top:40px;
    right: 190px;
}

/* Filter Page */

.filterPage {
    z-index: 6;
    padding-top: 47px;
    padding-right: 25px;
    padding-left: 25px;
}

.filterPage-title {
    margin-left: 31px;
}

.filterPage-title, .filterPage-category {
    font-size: 20px;
}

.filterPage-category {
    margin-bottom: 25px;
    margin-top: 30px;
}

.filterPage-show-container {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterPage-show-btn {
    height: 30px;
}

/* Create Card */
.createCard_categories{
    margin-bottom: 2vh;
}
.createCard {
    padding: 15px 15px;
}

.createCard-title {

    font-family: OpenSansBold;

    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 25px;
}

.createCard_categories-subtitle {

    font-family: OpenSansBold;

    font-size: 20px;
    margin-bottom: 25px;
}

.createCard_categories-select {

    width: 325px;
    height: 47px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    border: none;
    padding: 12px 20px;

}

.createCard_categories-select + .createCard_categories-select {
    margin-top: 15px;
}

.descriptionCard {
    margin-top: 50px;
}

.descriptionCard-title {
    font-size: 20px;
    margin-bottom: 25px;
}

.createCard_description {
    width: 325px;
    height: 185px;
    background-color: rgba(236, 236, 236, 1);
    border: none;
    border-radius: 10px;
    padding: 15px 20px;
}

.createCard-char_title {
    font-size: 20px;
    margin-top: 50px;

}

.sizeCard {
    margin-top: 50px;
}

.characteristic_item + .characteristic_item {
    margin-top: 10px;
}

.characteristic_item-label {

    font-family: OpenSansSemiBold;

    font-size: 18px;
    font-weight: bold;
    margin-bottom: 7px;
}
/*.column{*/
/*    margin-bottom: 3vh;*/
/*}*/
.characteristic_item-values {
    /*display: flex;*/
    /*align-items: center;*/
    /*text-align: left;*/
    width: 100%;
}

/*.characteristic_item-checkbox {*/
/*    width: 24px;*/
/*    height: 24px;*/
/*    margin-right: 13px;*/
/*}*/

.characteristic_item-text {
    font-size: 14px;
    width: 100%;
}

.createCard_characteristics-title {
    font-family: OpenSansBold;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 25px;
}

.createCard_select {
    width: 325px;
    height: 47px;
    background-color: rgba(236, 236, 236, 1);
    border: none;
    border-radius: 10px;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.createCard_characteristics-input {
    width: 325px;
    min-height: 47px;
    height: fit-content;
    background-color: rgba(236, 236, 236, 1);
    border: none;
    border-radius: 10px;
    padding: 10px;
}

.size_block + .size_block {
    margin-top: 15px;
}

.size_standart-checkbox {
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.size_standart-name {
    font-family: OpenSansBold;
    font-size: 18px;
    font-weight: bold;
    margin-right: 25px;
}

.size_standart-price {
    color: #B5B7BD;
    font-weight: bold;
    font-size: 16px;
}

.size_standart-description {
    font-size: 10px;
    color: #838690;
    line-height: 14.77px;
    margin-top: 10px;
}

.size_standart-btn {
    font-size: 16px;
    color: #070C20;
    font-weight: bold;
    margin-top: 10px;
}

.size_standart-preview {
    margin-top: 25px;
    margin-left: 40px;
}



.characteristic_item-checkbox {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    display: none;
}

/* для элемента label связанного с .characteristic_item-checkbox  */
.characteristic_item-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin: 5px 0;
}
/* создание в label псевдоэлемента  before со следующими стилями */
.characteristic_item-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    border: solid 1px rgba(181, 183, 189, 1);
    border-radius: 4px;
    margin-right: 0.5rem;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

/* стили при наведении курсора на радио */
.characteristic_item-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
}

/* стили для активной радиокнопки (при нажатии на неё) */
.characteristic_item-checkbox:not(:disabled):active::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

/* стили для радиокнопки, находящейся в фокусе */
.characteristic_item-checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.characteristic_item-checkbox:focus:not(:checked)+label::before {
    border-color: #80bdff;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.characteristic_item-checkbox:checked+label::before {
    border: none;
    background-color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для радиокнопки, находящейся в состоянии disabled */
.characteristic_item-checkbox:disabled+label::before {
    background-color: #e9ecef;
}

/*for Radio*/

.mob-input{
    display: none;
}

/* для элемента label связанного с .mob-input  */
.mob-input+label {
    display: inline-flex;
    align-items: center;
    user-select: none;

}
/* создание в label псевдоэлемента  before со следующими стилями */
.mob-input+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: solid 1px rgba(181, 183, 189, 1);
    border-radius: 4px;
    margin-right: 0.5rem;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

/* стили при наведении курсора на радио */
.mob-input:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
}

/* стили для активной радиокнопки (при нажатии на неё) */
.mob-input:not(:disabled):active::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

/* стили для радиокнопки, находящейся в фокусе */
.mob-input:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.mob-input:focus:not(:checked)+label::before {
    border-color: #80bdff;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.mob-input:checked+label::before {
    border: none;
    background-color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для радиокнопки, находящейся в состоянии disabled */
.mob-input:disabled+label::before {
    background-color: #e9ecef;
}
/*for Radio*/


/* Характеристики  */

/* .сharacteristics_child-flex{
    display: flex;
} */
/* ._child-flex-value{
    display: flex;
} */
.card_сharacteristics-title {
    margin-bottom: 15px;
}

.createCard_textarea {
    width: 325px;
    height: 185px;
    background-color: #ECECEC;
    border-radius: 10px;
    border: none;
    padding: 16px 20px;
}




@media (min-width: 768px) {
    .board_page {
        margin: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: 'Open Sans', sans-serif;
    }

    .profile_search {
        margin: 0 10px 0 0;
        height: 47px;
        background-color: rgba(236, 236, 236, 1);
        border-radius: 10px;
        margin-top: 35px;
        margin-bottom: 30px;
        padding: 13px 30px;
    }

    .profile_info-name {
        font-size: 20px;
    }

    .profile_info-phone {
        font-size: 16px;
        font-weight: 400;
    }

    .profile_info-img {
        width: 90px;
        height: 90px;

    }

    /* Card  */
    .card_title {
        font-size: 28px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .card_price {
        font-size: 38px;
        font-weight: 700;
        font-family: 'OpenSansBold';
    }

    .card_seller-title {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    .card_page-count {
        font-size: 18px;
    }

    .card_seller-address {
        font-size: 16px;
        font-weight: 400;
    }

    .card_description-title {
        font-family: 'OpenSansBold';
        font-weight: 700;
        font-size: 24px;
    }

    .card_description-text {
        font-size: 14px;
        font-weight: 400;
    }

    .card_statistic-text {
        font-weight: 400;
        font-size: 14px;
    }

    .card_backbtn {
        font-family: 'OpenSansBold';
        font-weight: 600;
        font-size: 18px;
    }

    .profile_cards-title {
        font-family: 'OpenSansBold';
        font-size: 24px;
        font-weight: 700;
    }
}

/* Support Page */

.appeals {
    padding: 20px 20px;
    padding-bottom: 60px;
}

.appeals-title {
    font-size: 20px;
    margin-bottom: 30px;
    font-family: 'OpenSansBold';
    margin-left: 15px;
}
