.card_favorite {
    z-index: 3;
    position: absolute;
    top: -1px;
    right: -10px;
    padding: 20px;
}

.card {
    width: 156px;
    position: relative;
}

.card_img-image {
    width: 156px;
    height: 210px;
    margin-bottom: 8px;
    border-radius: 15px;
    object-fit: cover;
}

.card_info-title {
    font-size: 14px;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card_info-address, .card_info-time {
    font-size: 10px;
    font-family: OpenSansRegular;
    font-weight: lighter;
}

.card_info-address {
    color: #838690;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card_info-time {
    color: #B5B7BD;
    margin-bottom: 8px;
}

.card_info-price {
    font-size: 18px;
    font-family: OpenSansBold;
}


.long_img {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 325px;
    height: 53px;
    border-radius: 10px;
    object-fit: cover;
}

/* Premium */

.premium_card {
    width: 325px;
    margin-bottom: 25px;
    border-radius: 10px;
    position: relative;
}

.premium_card-img {
    width: 325px;
    height: 189px;
    border-radius: 20px;
    object-fit: cover;
}

.premium_card-title {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.premium_card-address {
    font-size: 10px;
    color: #838690;
    margin-bottom: 4px;
}

.premium_card-time {
    font-size: 10px;
    color: #B5B7BD;
    margin-bottom: 8px;
}

.premium_card-price {
    font-size: 18px;
}

/* Vip */

.vip_card {
    padding-right: 15px;
    padding-left: 15px;
    width: 355px;
    margin-top: 40px;
    position: relative;
    margin-bottom: 15px;
}

.vip_card .card_favorite {
    top: 0;
    right: 10px;
}

.vip_card-img {
    width: 325px;
    height: 199px;
    border-radius: 10px;
    margin-bottom: 15px;
}

/* Standart plus */

.plus_card {
    width: 156px;
    position: relative;
}

.plus_card_img-image {
    width: 156px;
    height: 210px;
    margin-bottom: 8px;
    border-radius: 15px;
}

.plus_card_info-title {
    font-size: 14px;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.plus_card_info-address, .plus_card_info-time {
    font-size: 10px;
    font-family: OpenSansRegular;
    font-weight: lighter;
}

.plus_card_info-address {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 4px;
}

.plus_card_info-time {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 8px;
}

.plus_card_info-price {
    font-size: 18px;
    font-family: OpenSansBold;
    color: rgba(255, 255, 255, 1);
}

.similar_title {
    font-size: 20px;
    margin-bottom: 25px;
}

@media (min-width: 768px) {

    /* Premium  */
.premium_card {
    width: 708px;
    margin-bottom: 25px;
    border-radius: 10px;
    position: relative;
}

.premium_card-img {
    width: 708px;
    height: 245px;
    border-radius: 20px;
    object-fit: cover;
}

.premium_card-title {
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.premium_card-address {
    font-size: 12px;
    font-weight: 400;
    color: #838690;
    margin-bottom: 4px;
}

.premium_card-time {
    font-size: 12px;
    font-weight: 400;
    color: #B5B7BD;
    margin-bottom: 8px;
}

.premium_card-price {
    width: 10vh;
    font-size: 20px;
    font-family: 'OpenSansBold';
    font-weight: 600;
}
   /* Premium  */
.long_img {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 708px;
    height: 53px;
    border-radius: 10px;
    object-fit: cover;
}
    /* Premium  */

    /* Card  */
.card {
    width: 166px;
    position: relative;
}
.card_img-image {
    width: 166px;
    height: 210px;
    margin-bottom: 8px;
    border-radius: 15px;
    object-fit: cover;
}
.card_favorite{
    padding: 12px 20px;
}
/* Vip  */
.vip_card {    
    margin: 15px;
}

}