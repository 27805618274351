@font-face {
    font-family: 'OpenSansRegular';
    src: local('OpenSans-Regular'),
    url('../src/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansBold';
    src: local('OpenSans-Bold'),
    url('../src/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: local('OpenSans-SemiBold'),
    url('../src/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: OpenSansRegular;
    position: relative;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: 'OpenSansRegular';
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}
/* Глобальные элементы размеров */
.h-38 { height: 38px;}
.h-100{ height: 100px; }
.h-200{ height: 200px; }
.h-300{ height: 300px; }
.h-400{ height: 400px; }
.h-500{ height: 500px; }
.h-600{ height: 600px; }
.h-700{ height: 700px; }
.h-800{ height: 800px; }
.h-max{ height: 100% }

.w-100{ width: 100px; }
.w-200{ width: 200px; }
.w-300{ width: 300px; }
.w-400{ width: 400px; }
.w-500{ width: 500px; }
.w-600{ width: 600px; }
.w-700{ width: 700px; }
.w-800{ width: 800px; }
.w-max{ width: 100% }

.fz-8 { font-size: 8px; }
.fz-9 { font-size: 9px; }
.fz-10 { font-size: 10px; }
.fz-12 { font-size: 12px; }
.fz-14 { font-size: 14px; }
.fz-16 { font-size: 16px; }
.fz-18 { font-size: 18px; }
.fz-20 { font-size: 20px; }
/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

input {
    border: none;
    background-color: transparent;
    /*width: 100%;*/
    height: 100%;
}

button {
    background-color: transparent;
    border: none;
}

.container {
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
}

.wrapper {
    width: 325px;
    margin: 0 auto;

}

.icon {
    margin: 0 auto;
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}
.space-evenly{
    justify-content: space-evenly;
}

.center {
    justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;

}

.grid {
    display: grid;
    grid-column-gap: 13px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}

.block {
    display: block;
}

.relative {
    position: relative;
}

.noLink {
    text-decoration: none;
    color: black;
}

.mr-20{
    margin-right: 20px;
}

.pd-14 {
    padding: 14px;
}

.pd-l-30{
    padding-left: 30px;
}
.visibal-collapse{
    visibility: collapse;
    display: none !important;
}
a{
    color: inherit;
    text-decoration: none;
}
/* для сообщений  */
.FixBlock{
    width: 100%;
    background: white;
    height: 100px;
    position: fixed;
    z-index: 2;
}
.FixAnotherBlock{
    display: block;
    position: fixed;
    height: 100px;
    bottom: 0px;
    width: 100%;
    background: white;
}
.notice {
    z-index: 4;
    position: absolute;
    right: 0;
    top: -10px;
    content: " ";
    border-radius: 50%;
    background-color: red;
    width: 10px;
    height: 10px;
}
/* для сообщений  */


@media (min-width: 768px){
    .container{
      max-width: 768px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .wrapper {
        width: auto;
        padding: 0px 30px;
        margin: 0 auto;
    
    }
    .img_25 {
        width:25px;
    }
  }