.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 40px ;
    position: fixed;
    bottom: 0;
    left: 0; 
    width: 100%;
    height: 83px;
    background-color: rgba(255, 255, 255, 0.87);
    backdrop-filter: blur(3px)!important;
    z-index: 5;
}


.footer_section-btn {
    position: absolute;
    top: -55px;
    left: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background-color: rgba(22, 22, 22, 1);
    border-radius: 50%;
    cursor: pointer;
}

.footer_section-icon {
    width: 12px;
    height: 12px;
}

@media (min-width: 768px){
    .footer{
      max-width: 768px;
      left: auto;
    }
    .footer_section{
        display: block;
        padding: 20px;
    }
    
  }
