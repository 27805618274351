.upload_block + .upload_block {
    margin-top: 40px;
}

/*.deleteImg_stBtn{*/
/*    display: none;*/
/*}*/
.deleteImg_stBtn{
    background-color: white;
    border-radius: 100px;
    height: 25px;
    width: 25px;
    margin-right: 5px;
    margin-top: 10px;
}

.deleteImg_premiumBtn{
    top: 5px;
    z-index: 3;
    right: 5px;
    background-color: white;
    border-radius: 100px;
    height: 25px;
    width: 25px;
}

.checkedImg_label{
    font-family: OpenSansSemiBold;
    color: #fff;
    background-color: #8a8a8a;
    border-radius: 15px;
    padding: 3px;
    margin-left: 5px;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
}
.checkedImg_label.active {
    background-color: #A4F68E;
    color: #262626;
}
.handel_image{
    /*width: 140px;*/
    position: relative;
    top: 15px;
    z-index: 3;
    height: 0;
    justify-content: space-between;
    align-items: center;
}

.upload_block-title {
    font-size: 18px;
    font-family: OpenSansBold;
}

.upload-input {
    display: none;
}

.upload_file_input {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ECECEC;
    border-radius: 20px;
    transition: .5s;
    /*margin-left: 10px;*/
}

.upload_file_input:hover {
    background-color: #F6F6F6;
}

.upload_premium-label {
    width: 750px;
    height: 222px;
    margin-top: 20px;
}

.upload_vip-label {
    width: 368px;
    height: 222px;
}

.upload_standartPlus-label {
    width: 169px;
    height: 222px;
}

.upload_standart-label {
    width: 156px;
    height: 210px;
    /*width: 132px;*/
    /*height: 178px;*/
}

.upload_info-premium {
    margin-top: 20px;
}

.upload_info-premium-text {
    font-size: 16px;
    margin-bottom: 5px;
}

.upload_info-premium-format {
    color: #B5B7BD;
    font-size: 14px;
}

.upload_info {
    margin-left: 35px;
}

.images-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 10px;
    /*grid-template-rows: 188px;*/
}

.images-flex_row {

}

.images-flex_column {
    border-radius: 15px;
    overflow: hidden;
    height: 210px;
    width: 156px;
}
.images-vip-flex_column {
    border-radius: 15px;
    overflow: hidden;
    height: 210px;
    width: 345px;
}
.images-premium-flex_column {
    border-radius: 15px;
    overflow: hidden;
    height: 143px;
    width: 354px;
}


.uploadPhoto-btn {
    width: 230px !important;
    height: 54px;
    background-color: rgba(22, 22, 22, 1);
    color: white;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 10px;
}

.uploadPhoto-btn-next {
    width: 230px;
    height: 54px;
    border-radius: 10px;
    border: 1px solid rgba(236, 236, 236, 1);
    font-weight: bold;
    margin-top: 10px;
    margin-left: 10px;
}

.mb-20{
    margin-bottom: 20px;
}
.mt-20{
    margin-top: 20px;
}
.ml-20{
    margin-left: 20px;
}
