.backbtn_icon {
    margin-right: 21px;
}

.message_btn {
    background-color: #F6F6F6;
    border-radius: 10px;
    margin-right: 13px;
    cursor: pointer;
    transition: .5s;
}

.message_btn:hover {
    background-color: rgba(236, 236, 236, 1);
}

.phone_btn {
    color: white;
    background-color: #161616;
    border-radius: 10px;
    cursor: pointer;
    transition: .25s;
}

.phone_btn:hover {
    background-color: rgba(131, 134, 144, 1);
}

.w-54px {
    width: 54px;
    height: 54px;
}

.w-93px {
    width: 93px;
    height: 54px;
}

.w-156px {
    width: 156px;
    height: 54px;
}

.w-275px {
    width: 275px;
    height: 54px;
}

.w-325px {
    width: 325px;
    height: 54px;
}
.w-100P{
    width: 100%;
    height: fit-content;
    padding: 10px;
}




.white_text {
    color: white;
}

