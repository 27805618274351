.myCard + .myCard::before {
    display: block;
    content: '';
    background-color: rgba(181, 183, 189, 1);
    width: 100%;
    height: 1px;
    opacity: 0.3;
    margin-bottom: 30px;
}

.myCard {
    position: relative;
    margin-bottom: 30px;
    z-index: 0;
}

.myCard_img {
    width: 111px;
    margin-right: 15px;
}

.myCard_img-image {
    width: 111px;
    height: 149px;
    border-radius: 20px;
    object-fit: cover;
}

.myCard_info-dop {
    width: 180px;
}

.myCard_info-type {
    color: rgba(181, 183, 189, 1);
    font-family: OpenSansSemiBold;
}

.myCard_info-main {
    margin-top: 12px;
}

.myCard_info-title {
    font-size: 16px;
    margin-bottom: 5px;
    font-family: OpenSansSemiBold;
}

.myCard_info-price {
    font-size: 18px;
    font-family: OpenSansBold;
}

.myCard_info-icons {
    margin-top: 8px;
}

.myCard_info-icon {
    margin-right: 7px;
}

@media (min-width: 768px){

    .myCard_img {
        width: 166px;
        margin-right: 15px;
    }
    
    .myCard_img-image {
        width: 166px;
        height: 223px;
        border-radius: 20px;
        object-fit: cover;
    }
    .myCard_info{
        width: -webkit-fill-available;
    }
        .myCard_info-dop {
            width: auto;
        }
        .myCard_info-title {
            font-size: 24px;
            font-family: 'OpenSansBold';
            font-weight: 600;
        }
        
        .myCard_info-price {
            font-family: 'OpenSansBold';
            font-size: 28px;
            font-weight: 700;
        }
        .myCard_info-main {
            margin-top: 12px;
        }
    .f_column{
         display: flex;
         flex-direction: column;
         justify-content: space-between;
    }
        

    
}