
.booking {
    margin-top: 110px;
}

.booking-title {
    font-size: 32px;
    font-family: OpenSansBold;
    margin-bottom: 43px;
}
/*.flex{*/
/*    display: flex;*/
/*}*/
.another_title{
    font-size: 20px;
    font-family: OpenSansBold;
    margin-top: 50px;
    margin-bottom: 25px;
}

.another_title{
    font-size: 20px;
    font-family: OpenSansBold;
    margin-top: 50px;
    margin-bottom: 25px;
}

.booking_startDate {
    margin-right: 45px;
}

.booking_input {
    width: 237px;
    height: 47px;
    padding: 15px;
    background-color: #ECECEC;
    color: #B5B7BD;
    border-radius: 10px;
}

.booking_label {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: OpenSansBold;
}

.booking_info {
    margin-top: 45px;
}

.booking_info-title {
    font-family: OpenSansBold;
}

.booking_info-title + .booking_info-title {
    margin-top: 40px;
}

.booking_info-name {
    font-size: 18px;
    text-align: left;
}

.booking_info-text {
    font-weight: bold;
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 139px;
    height: 47px;
    text-align: center;
    border-radius: 10px;
    font-size: 24px;
    margin-bottom: 11px;
}

.upload_photo-title {
    display: block;
    font-size: 16px;
    font-family: OpenSansSemiBold;
    margin-bottom: 17px;
}

.p-text_style{
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    margin-bottom: 3vh;
}
.p-informaiton_block{
    width: 210px;
    font-family: "Open Sans",sans-serif;
    font-size: 10px;
    color: #838690;
}

.label_calc {
    display: block;
    font-family: OpenSansBold;
    margin-bottom: 10px;
}