.modal {
    width: 100%;
    height: 100%;

    background-color: rgba(47, 47, 47, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: .5s;
    pointer-events: none;
}

.modal.activeModal {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 30px 30px ;
    background-color: white;
    z-index: 2;
    transform: scale(0.5);
    transition: .4s;
    overflow: auto;
    max-height: 690px;
}

.modal_content.activeModal {
    transform: scale(1);
}


/* OffCard */

.off_card-bold {
    font-size: 20px;
    font-family: OpenSansBold;
}

.off_card-subtitle {
    margin-top: 10px;
    margin-bottom: 25px;
    color: rgba(131, 134, 144, 1);
}

.off_card-btn + .off_card-btn {
    margin-left: 15px;
}

.off_card-cross {
    position: absolute;
    top: -30px;
    right: -30px;
    margin: 20px;
}

.missPassword{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.missPassword_close {
    position: absolute;
    top: 0;
    right: 0px;
}

.missPassword_title {
    font-size: 20px;
    margin-top: 70px;
}

.missPassword_subtitle {
    display: block;
    font-size: 16px;
    width: 251px;
    color: rgba(131, 134, 144, 1);
    margin-top: 10px;
}

.missPassword_input {
    width: 275px;
    height: 47px;
    margin-top: 20px;
    margin-bottom: 25px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    padding: 15px;
}

.missPassword_input_add {
    width: 275px;
    height: 47px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    padding: 15px;
}

.auth_error {
    font-size: 12px;
    color: #FC372A;
    margin-top: -20px;
    margin-bottom: 20px;
}

.missPassword_sendAgain {
    font-family: OpenSansSemiBold;
    margin-right: 10px;
    margin-bottom: 25px;
    color: rgba(181, 183, 189, 1);
}
/* Filter adAdppeal  */
.add_appeal-title {
    font-size: 18px;
    min-width: 250px;
    text-align: left;
}


.Edited_appeal-select{
    position: relative;
    margin-top: 10px;
}

.Edited_filter-header {
    font-size: 14px;
    padding: 15px;
    box-shadow: 0px 5px 35px 0px rgba(38, 38, 38, 0.1);
    background-color: rgba(236, 236, 236, 1);
    border-radius: 10px;
    /*height: 38px;*/
    height: fit-content;
    margin-bottom: 1vh;
    cursor: pointer;
    z-index: 0;
}

.Edited_filter-header:focus {
    border: 1px solid black;
}

.filter_select-body-none {
    z-index: 1;
    display: none;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.Edited_filter_select-body {
    background-color: white;
    z-index: 1;
    padding: 5px;
    margin-top: 5px;
    height: 150px;
    overflow-y: scroll; 
    border-radius: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 6px 40px 0px #2626261A;
}

.Edited_filter_select-item {
    font-size: 14px;
    height: 40px;
    text-align: left;
    border-radius: 10px;
    cursor: pointer;
    padding: 5px ;
    z-index: 3;
}

.add_appeal-textarea {
    width: 100%;
    height: 150px;
    text-wrap: wrap;
    border-radius: 10px;
    margin: 10px 0px;
    resize: none;
    padding: 10px;
    font-size: 14px;
    border: 1px solid rgba(128, 128, 128, 0.74); 
    background-color: rgba(236, 236, 236, 1);
}

