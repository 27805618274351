.container_preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(235, 235, 235, 0.5);
}

.loader {
    position: relative;
}

.loader img {
    object-fit: cover;
}

.loader-text {
    bottom: -15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-style: italic;
}

.circle-preloader {
    top: -80px;
    left: -60px;
    width: 256px;
    height: 256px;
    background-color: rgba(193, 75, 75, 0.7);
    border-radius: 50%;
    position: absolute;
    transform-origin: center;
}

.circle-preloader.circle-front {
    opacity: 0; /* Set initial opacity to 0 */
    animation: fade-in .8s cubic-bezier(0.55, 0, 1, 0.45) forwards; /* Set infinite to continue the animation indefinitely */
}

.circle-preloader.circle-rear {
    opacity: 0; /* Set initial opacity to 0 */
    animation: rear-grow 1s infinite, fade-out 1s 1s infinite; /* Set infinite to continue the animation indefinitely */
}

@keyframes rear-grow {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.4);
    }
}

@keyframes fade-out {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    75% {
        transform: scale(1.2);
        opacity: 1;
    }
    84% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    93% {
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
