.profile_card_submenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 188px;
    background-color: white;
    box-shadow: 0px 6px 50px 0px rgba(38, 38, 38, 0.25);
    border-radius: 10px;
    padding: 5px;
}

.profile_card_submenu-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 178px;
    height: 47px;
    border-radius: 10px;
}


.profile_card_submenu-item:hover {
    cursor: pointer;
    width: 100%;
    background-color: rgba(246, 246, 246, 1);
}

/* Input */

.input + .input {
    margin-top: 6px;
}

.input_label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
}

.input_form {
    width: 325px;
    height: 47px;
    border-radius: 10px;
    padding: 15px;
}

.usually{
    background-color: #ECECEC;
}

.error {
    background-color: rgba(255, 220, 220, 1);
}

.checkbox {
    width: 17px;
    height: 17px;
    margin-right: 10px;
}

.checkbox_label {
    font-size: 14px;
}

.danger_box {
    margin-top: 10px;
    margin-left: 25px;
    align-self: flex-start;
}

.danger_text {
    color: rgba(252, 55, 42, 1);
    margin-left: 10px;
}

.card_favorite_icon {
    width: 18px;
    height: 16px;
}


