
.dialog {
    width: 325px;
    margin: 30px 0px;
}

.dialog + .dialog {
    margin-top: 30px;
}

.dialog_img {
    width: 69px;
    height: 92px;
    border-radius: 7px;
}

.dialog_info {
    margin-left: 15px;
    width: 100%;
    height: 92px;
}

.dialog_title {
    font-size: 16px;
    font-family: OpenSansBold;
    color: black;
}

.dialog_info_name-price {
    font-size: 14px;
    color: black;
}

.dialog_messCount {
    background-color: rgba(252, 55, 42, 1);
    width: 21px;
    height: 21px;
    color: white;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog_notification {
    width: 100%;
    position: relative;
}

.dialog_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
}

.dialog_status {
    font-size: 14px;
    font-weight: 600;
}

.dialog_date {
    font-size: 12px;
    color: rgba(181, 183, 189, 1);
}

.green {
    color: rgba(16, 201, 90, 1);
}
.gray {
    color: gray;
}

.mess {
    width: 100%;
    height: 100%;
margin-top: 10vh;
}

.mess + .mess {
    margin-top: 20px;
}

.mess_time {
    color: rgba(181, 183, 189, 1);
    font-size: 12px;
    display: block;
    width: 100%;
}

/* Dialog Sender*/


.dialog_sender {
    display: flex;
    align-items: flex-end;
}

.tringle {
    width: 0px;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 15px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 6px 40px 0px rgba(38, 38, 38, 0.1);

}

.message_block {
    width: 245px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 6px 40px 0px rgba(38, 38, 38, 0.1);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
}

.dialog_recipient {
    display: flex;
    justify-content: flex-end;
}
.dialog_sender_btn{
    /* width: 54px; */
    /* height: 54px; */
    padding: 10px 15px;
    background-image: url('../../asserts/messages/send_icon.svg');
    background-repeat: no-repeat;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
    transition: .25s;
}

.chat_page-header{
    background-color: white;
    position: fixed;
    z-index: 3;
}


