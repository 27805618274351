.carousel-container {
    position: relative;
    width: 325px; /* Размер карусели */
    margin: 15px auto;
    overflow: hidden;
}
.carousel-container .dots {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.panel {
    width: 325px;
    height: 427px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;
}

.content {
    padding: 20px;
}
