.favorite_item {
    margin-top: 30px;
}

.favorite_item + .favorite_item::before {
    display: block;
    content: '';
    width: 325px;
    height: 1px;
    background-color: rgba(181, 183, 189, 0.3);
    margin-bottom: 30px;
}

.favorite_image {
    width: 111px;
    height: 149px;
    border-radius: 10px;
    object-fit: cover;
}

.favorite_info {
    width: 100%;
    margin-left: 15px;
}

.favorite_category {
    width: 100%;
    display: block;
    color: rgba(181, 183, 189, 1);
    font-size: 14px;
    margin-bottom: 15px;
}

.favorite_title {
    font-size: 16px;
}

.favorite_price {
    font-size: 18px;
}

.favorite_status {
    font-size: 14px;
    color: rgba(16, 201, 90, 1);
    font-family: OpenSansSemiBold;
    margin-top: 17px;
}

.favorite_time, .favorite_views {
    color: rgba(181, 183, 189, 1);
    font-size: 12px;
}

@media (min-width: 768px) {
    .favorite_image {
        width: 166px;
        height: 223px;
    }
    
}
